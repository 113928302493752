<template>
    <div class="testing-tool" @click="toggleOpen">
        <img
            alt="arrow"
            src="/images/arrow-left-big.svg"
            class="toggle-arrow toggle-arrow-left"
            :class="{ opened }"
        />
        <span v-if="!opened">Test tool</span>
    </div>

    <div class="testing-tool-wrap" :class="{ opened }">
        <div class="testing-tool-content">
            <div
                v-if="inCapacitorBuildEnv && inCapacitorBuildEnv()"
                class="info-block"
            >
                <span>Mobile web bundle:</span>
                <span>
                    {{
                        (this.capacitorBundleVersion || 'undefined').split(
                            /(.*\+b\.\d+\.)/,
                        )[1]
                    }}
                </span>
                <span>
                    {{
                        (this.capacitorBundleVersion || '').split(
                            /(.*\+b\.\d+\.)/,
                        )[2]
                    }}
                </span>
                <span>Set capacitor channel:</span>
                <div class="action-block">
                    <ButtonSmall
                        text="payments"
                        @click="setCapgoChannel('payments')"
                    />
                    <ButtonSmall text="dev" @click="setCapgoChannel('dev')" />
                </div>
            </div>

            <span>{{ `School premium: ${$user?.premium?.status}` }}</span>
            <span>{{
                `Subscription: ${isSubscribed ? subscriptionPlan : subscriptionStatus}`
            }}</span>
            <span>{{ `User XP: ${userXp}` }}</span>

            <ButtonSmall
                v-if="$user && $user.role !== 'teacher'"
                :type="isSubscribed ? ButtonType.Primary : ButtonType.Secondary"
                :text="`Subscribed UI ${isSubscribed ? 'ON' : 'OFF'}`"
                @click="switchIsSubscribed"
            />

            <div class="data-block">
                <ButtonSmall
                    :type="
                        showCounters ? ButtonType.Secondary : ButtonType.Primary
                    "
                    :text="showCounters ? 'Hide counters' : 'Show counters'"
                    @click="showCounters = !showCounters"
                />
                <pre v-if="showCounters" class="data">{{
                    JSON.stringify(userCounters, null, 2)
                }}</pre>
            </div>

            <div class="data-block">
                <ButtonSmall
                    :type="showUser ? ButtonType.Secondary : ButtonType.Primary"
                    :text="showUser ? 'Hide user' : 'Show user'"
                    @click="showUser = !showUser"
                />
                <pre v-if="showUser" class="data">{{
                    JSON.stringify($user, null, 2)
                }}</pre>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonSmall from '@/student/ui/button/ButtonSmall.vue';
import ButtonMedium, { ButtonType } from '@/student/ui/button/ButtonMedium.vue';
import { CAPACITOR_CODE_BUNDLE_VERSION_KEY } from '@/capacitor/init.js';
import { setCapgoChannel } from '@/capacitor/initCapacitorUpdates.js';
import { inCapacitorBuildEnv } from '@/core/helpers/utils.js';

export default {
    name: 'TestingTool',
    components: { ButtonMedium, ButtonSmall },
    data: () => ({
        ButtonType,
        opened: false,
        showCounters: false,
        showUser: false,
        capacitorBundleVersion: '',
    }),
    computed: {
        isSubscribed() {
            return this.$store.getters.subscribedUser;
        },
        subscriptionStatus() {
            return this.$user?.paymentInfo?.stripe?.subscription?.status;
        },
        userXp() {
            return (
                this.$user?.studentInfo?.homeGameV10SimpleTreeSocial?.xp || 0
            );
        },
        userCounters() {
            return this.$user?.role === 'teacher'
                ? {
                      liveGamesPlayed: this.$user?.liveGamesPlayed,
                      selfPacedGamesPlayed: this.$user?.selfPacedGamesPlayed,
                      liveGamesMin4PlayersPlayed:
                          this.$user?.liveGamesMin4PlayersPlayed,
                      selfPacedGamesMin4PlayersPlayed:
                          this.$user?.selfPacedGamesMin4PlayersPlayed,
                      gameDaysCounter: this.$user?.gameDaysCounter,
                  }
                : this.$user?.studentInfo?.counters;
        },
        subscriptionPlan() {
            return (
                this.$user?.studentInfo?.premiumPlanStatus?.typePlan || 'none'
            );
        },
    },
    mounted() {
        setTimeout(
            () =>
                (this.capacitorBundleVersion = localStorage.getItem(
                    CAPACITOR_CODE_BUNDLE_VERSION_KEY,
                )),
            1000,
        );
    },
    methods: {
        inCapacitorBuildEnv,
        setCapgoChannel,
        toggleOpen() {
            this.opened = !this.opened;
        },
        switchIsSubscribed() {
            const paymentInfo = this.$user?.paymentInfo;

            if (this.subscriptionStatus)
                paymentInfo.stripe.subscription.status = !this.isSubscribed
                    ? 'active'
                    : 'inactive';
            else
                paymentInfo.stripe = {
                    ...(paymentInfo.stripe || {}),
                    subscription: { status: 'active' },
                };

            this.$store.dispatch('v2/user/update', {
                paymentInfo,
            });
        },
    },
};
</script>

<style lang="scss">
.testing-tool {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10000;
    top: 30%;
    right: 0;
    box-sizing: border-box;
    padding: 5px;
    background-color: #6e0080;
    font-size: 14px;
    font-weight: 600;
    max-width: 80px;
    text-overflow: ellipsis;
}

.testing-tool-wrap {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    max-width: 100vw;
    z-index: 9999;
    visibility: hidden;
    overflow: auto;
    background-color: #6e0080b0;

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        align-items: unset;
    }
}

.testing-tool-content {
    display: flex;
    justify-items: start;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 0 50px 0 10px;
    row-gap: 5px;
    column-gap: 30px;
    overflow: auto;
    font-weight: 600;

    @media screen and (max-height: 450px) and (orientation: landscape) {
        width: calc(100vw - 120px);
    }

    @media screen and (max-height: 350px) and (orientation: landscape) {
        max-height: 310px;
    }

    .info-block {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .action-block {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .data-block {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .section {
        margin: 0 0 0 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .tt-input {
        width: 70px;
        height: 26px;
        border-radius: 7px;
        margin-left: 5px;
        font-size: 16px;
    }
}

.data {
    display: flex;
    max-width: 500px;
    max-height: 500px;
    overflow: auto;

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        max-width: calc(100vw / 3);
        max-height: calc(100vh - 30px);
    }
}

.testing-tool-wrap.opened {
    visibility: visible;
}

.toggle-arrow {
    cursor: pointer;

    @include mq($w-max-tablet, max) {
        width: 32px;
        height: 32px;
    }
}

.toggle-arrow-left:not(.opened) {
    margin-right: 16px;

    @media screen and (max-width: $w-max-lg) {
        margin-right: 6px;
    }
}
.toggle-arrow.opened {
    transform: rotateZ(180deg);
}

.button-small {
    max-width: 100px;
}
.button-small .text-wrapper {
    font-size: 14px;
    line-height: 14px;
    text-wrap: wrap;
}
</style>
